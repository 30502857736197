<template>
    <form class="form"
        novalidate="novalidate"
        id="st_modal_decision_form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="form-group">
            <label>
                {{ fields.county_id.label }} *
            </label>
            <div>
                <st-autocomplete
                    v-model="decision[fields.county_id.name]"
                    :options="fields.county_id.options"
                    :ref="fields.county_id.name"
                    :name="fields.county_id.name"
                    :disabled="true"

                >
                </st-autocomplete>
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ fields.target_id.label }} *
            </label>
            <div>
                <st-autocomplete
                    v-model="decision[fields.target_id.name]"
                    :options="fields.target_id.options"
                    :ref="fields.target_id.name"
                    :name="fields.target_id.name"
                    :disabled="isExtension"
                    :fields="fields"
                    :model="decision"
                    :formValidation="fv"
                    @change="validateField(fields.target_id.name)"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ fields.purpose_id.label }} *
            </label>
            <div>
                <st-autocomplete
                    v-model="decision[fields.purpose_id.name]"
                    :options="fields.purpose_id.options"
                    :ref="fields.purpose_id.name"
                    :name="fields.purpose_id.name"
                    :disabled="isExtension"
                    @change="validateField(fields.purpose_id.name)"
                >
                </st-autocomplete>
            </div>
        </div>
        <div class="form-group">
            <label>
                {{ fields.description.label }} *
            </label>
            <st-input-text
                v-model="decision[fields.description.name]"
                :ref="fields.description.name"
                :name="fields.description.name"
                :disabled="isExtension"
            />
        </div>
        <div class="form-group">
            <label>
                {{ fields.number.label }} *
            </label>
            <st-input-text
                v-model="decision[fields.number.name]"
                :ref="fields.number.name"
                :name="fields.number.name"
            />
        </div>
         <div class="form-group">
            <label>
                {{ fields.entry_date.label }} *
            </label>
            <st-button-only-date-picker
                v-model="decision[fields.entry_date.name]"
                :name="fields.entry_date.name"
                :ref="fields.entry_date.name"
                @change="validateField(fields.entry_date.name, fields.expiration_date.name)"
            >
            </st-button-only-date-picker>
        </div>
        <div class="form-group">
            <label>
                {{ fields.expiration_date.label }} *
            </label>
            <st-button-only-date-picker
                v-model="decision[fields.expiration_date.name]"
                :name="fields.expiration_date.name"
                :ref="fields.expiration_date.name"
                :disabled="!decision[fields.entry_date.name]"
                @change="validateField(fields.expiration_date.name)"
                :min="entryDate"
            >
            </st-button-only-date-picker>
        </div>
    </form>
</template>

<script>
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { mapActions, mapGetters } from 'vuex';

import Message from '@/shared/message/message';
import { FormSchema } from '@/shared/form/form-schema';
import { DecisionModel } from '@/modules/administrator/models/decision-model';

const { fields } = DecisionModel;
const formSchema = new FormSchema([
    fields.description,
    fields.number,
    fields.entry_date,
    fields.expiration_date,
    fields.target_id,
    fields.purpose_id,
])

export default {
    name: 'ManageDecisionForm',
    props: {
        selectedDecision: {
            type: Object,
            required: true
        },
        institution: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        isExtension: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            decision: {
                description: '',
                number: '',
                entry_date: '',
                expiration_date: '',
                target_id: '',
                purpose_id: '',
                county_id: '',
            },
            fields,
            fv: null
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
        }),
        currentDate() {
            return new Date();
        },
        entryDate() {
            if (!this.decision.entry_date) return this.currentDate;
            return new Date(this.decision.entry_date);
        }
    },
    methods: {
        ...mapActions({
            addDecision: 'administrator/decisionForm/addDecision',
            updateDecision: 'administrator/decisionForm/updateDecision',
        }),
        clearModal() {
             this.decision = {
                description: '',
                number: '',
                entry_date: '',
                expiration_date: '',
                target_id: '',
                purpose_id: '',
                county_id: '',
            }
        },
        hide() {
            this.$emit('cancelForm');
            this.clearModal();
        },
        manageDecision() {
            this.fv.validate()
            .then((status) => {
                if (status === 'Valid') {
                    if (this.editMode) {
                        this.onUpdateDecision();
                    } else {
                        this.onAddDecision();
                    }
                }
            });
        },
        onAddDecision() {
            const payload = {
                ...this.decision,
                target_name: this.fields.target_id.options.find((el) => el.value === this.decision.target_id)?.text,
                purpose_name: this.fields.purpose_id.options.find((el) => el.value === this.decision.purpose_id)?.text,
                county_name: this.fields.county_id.options.find((el) => el.value === this.decision.county_id)?.text,
                organisation_id: this.institution.id,
                organisation_name: this.institution.name,
                is_extension: this.isExtension,
            };

            if (this.isExtension) {
                payload.extended_parent_id = this.decision.id;
                delete payload.id;
            }

            this.addDecision(payload)
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('DECISION.SUCCESS.ADD');
            })
        },
        onUpdateDecision() {
             const payload = {
                ...this.decision,
                target_name: this.fields.target_id.options.find((el) => el.value === this.decision.target_id)?.text,
                purpose_name: this.fields.purpose_id.options.find((el) => el.value === this.decision.purpose_id)?.text,
                county_name: this.fields.county_id.options.find((el) => el.value === this.decision.county_id)?.text,
            };

            this.updateDecision({id: this.decision.id, payload })
            .then(() => {
                this.$emit('submit');
                this.hide();
                Message.success('DECISION.SUCCESS.UPDATE');
            })
        },
        validateField(field, linkedField = '') {
            this.fv.validateField(field);

            if (linkedField) {
                this.$nextTick(() => {
                    this.decision[linkedField] = '';
                    if (!this.fv.fields[linkedField]) {
                        this.fv.addField(linkedField, {
                            validators: {
                                notEmpty: {
                                    message: this.$t('VALIDATION.REQUIRED_FIELD'),
                                },
                                date: {
                                    format: 'DD-MM-YYYY',
                                    message: this.$t("AUTH.VALIDATION.INVALID_DATE"),
                                },
                            },
                        });
                    }
                    this.fv.revalidateField(linkedField);
                });
            }
        },
    },
    created() {
        this.fields.county_id.options = this.fields.county_id.options.filter(
            (el) => {
                return el.value === this.defaultCountyId;
            }
        );
        this.decision.county_id = this.defaultCountyId;
    },
    mounted() {
        this.fv = createFormValidation('st_modal_decision_form', this.rules);
        if(this.editMode || this.isExtension) {
            this.decision = { ...this.selectedDecision};
            this.fv.addField(this.fields.expiration_date.name, {
                    validators: {
                        notEmpty: {
                            message: this.$t('VALIDATION.REQUIRED_FIELD'),
                        },
                        date: {
                            format: 'DD-MM-YYYY',
                            message: this.$t("AUTH.VALIDATION.INVALID_DATE"),
                        },
                    },
                })
        }
        if(this.isExtension && !this.editMode) {
            this.decision.number = '';
        }
    },
};
</script>
