<template>
  <div class="mt-8">
    <st-page :showHeader="false">
      <decision-list-filter
        :selectedInstitution="selectedInstitution"
        ref="listDecisionFilter"
      >
        <template #toolbar>
          <decision-list-toolbar
            :selectedInstitution="selectedInstitution"
            @addDecision="addDecision"
          />
        </template>
        <decision-list-table
          :selectedInstitution="selectedInstitution"
          @editDecision="editDecision"
          @updateDecision="updateDecision"
          @addExtension="addExtension"
        />
      </decision-list-filter>
      <manage-decision
        ref="manage-decision"
        :institution="selectedInstitution"
        :selectedDecision="selectedDecision"
        :editMode="editMode"
        :isExtension="isExtension"
        @updateDecision="updateDecision"
      />
    </st-page>
    <div class="row">
      <div class="col col-12 col-lg-6 mt-8" v-can:read="'decisionTarget'">
        <decision-target-list/>
      </div>
      <div class="col col-12 col-lg-6 mt-8" v-can:read="'decisionPurpose'">
        <decision-purpose-list />
      </div>
    </div>
  </div>
</template>

<script>
import DecisionListFilter from "@/modules/administrator/components/decision/DecisionListFilter";
import DecisionListTable from "@/modules/administrator/components/decision/DecisionListTable";
import DecisionListToolbar from "@/modules/administrator/components/decision/DecisionListToolbar";
import ManageDecision from "@/modules/administrator/components/decision/ManageDecisionModal.vue";
import DecisionTargetList from "@/modules/decision-target/pages/DecisionTargetList.vue";
import DecisionPurposeList from "@/modules/decision-purpose/pages/DecisionPurposeList.vue";
import decisionTargetModule from '@/modules/decision-target/decision-target-store';
import decisionPurposeModule from '@/modules/decision-purpose/decision-purpose-store';

export default {
  name: "DecisionList",
  props: {
    selectedInstitution: {
      type: Object,
      required: true,
    },
  },
  components: {
    DecisionListFilter,
    DecisionListTable,
    DecisionListToolbar,
    ManageDecision,
    DecisionTargetList,
    DecisionPurposeList,
  },
  data() {
    return {
      selectedDecision: {},
      editMode: false,
      isExtension: false,
    };
  },
  methods: {
    showModal() {
      this.$refs["manage-decision"].show();
    },
    addDecision() {
      this.editMode = false;
      this.isExtension = false;
      this.showModal();
    },
    updateDecision() {
      this.$refs.listDecisionFilter.refresh();
    },
    editDecision(data) {
      this.editMode = true;
      this.isExtension = data.item && data.item.is_extension;
      this.selectedDecision = data.item;
      this.showModal();
    },
    addExtension(data) {
      this.isExtension = true;
      this.editMode = false;
      this.selectedDecision = data.item;
      this.showModal();
    }
  },
   beforeCreate() {
        this.isModuleRegistred = true;
        this.$store.registerModule('decisionTarget', decisionTargetModule);
        this.$store.registerModule('decisionPurpose', decisionPurposeModule);
    },
    beforeDestroy() {
        if (this.isModuleRegistred) {
            this.$store.unregisterModule('decisionTarget');
            this.$store.unregisterModule('decisionPurpose');
        }
    }
};
</script>
